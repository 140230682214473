
#ProductsChart {
    width: 100%;
    margin-top: -14px;
  }
  
  .content-products{
    display: flex;
    text-align: center;
    width: 100%;
    margin-top: 1%;
    margin-bottom: 4px;

    & .products-chart--lapse {
      width: 44%;
      flex-basis: 44%;
    }

    & .products-chart--ui {
      width: 56%;
      flex-basis: 56%;
    }
  }