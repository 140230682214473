#UIDistribution {
    width: 50%;
    height: 363px !important;
    float: right;
  }

  #labelText{
    font-size: 16px;
    line-height: 22.88px;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    text-align: center;
  }

  #chartTitle{
    text-align: left;
    padding-top: 12px;
    padding-left: 15px;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Roboto';
  }

  .BarsBox{
    text-align: start;
    height: 400px !important;
    margin-top: 1%;
    margin-bottom: 4px;
    padding-left: 10px;
    margin-left: 5px;
  }

.tableHeaderText{
  font-size: 12px;
  font-weight: normal;
  color: #6a6c71;
  margin-bottom: 44px;
  padding-bottom: 3%;
  border-bottom: solid 1px #eef0f4;
  
}

.tableRowText{
  font-size: 13px;
  color: #312e40;
  font-weight: 500;
}


#tableLegend{
  width: 100%;
  margin-top: 40px;
  border-collapse: separate;
  border-spacing: 18px;
}
  
#legend {
  width: 45%;
  height: 363px;
  margin-left: 10px;
  float: left;
}

#legend .legend-item {
  margin: 10px;
  font-size: 15px;
  font-weight: bold;
}

#legend .legend-item .legend-value {
  font-size: 12px;
  font-weight: normal;
  margin-left: 22px;
}

#legend .legend-item .legend-marker {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  border-radius: 100%;
}

#legend .legend-item.disabled .legend-marker {
  opacity: 0.5;
  background: #ddd;
}
