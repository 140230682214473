.image {
    position: absolute;
}

.circle {
    position: relative;
    background-color: transparent;
    border: 1px solid #363ED325;
    border-radius: 50%;
    animation: 2s linear 0s infinite alternate adawdaw;
}

.circle-inner {
    position: absolute;
    width: 494px;
    height: 494px;
}

.circle-mid {
    width: 574px;
    height: 575px;
}
 
.circle-outer {
    position: absolute;
    width: 662px;
    height: 662px;
} 

@keyframes dddd {
    from {
        top: 0;
    }
    to {
        top: -500px;
    }
  }