.DistributionMap {
    text-align: center;
    height: 400px;
    margin-top: 1%;
    margin-bottom: 4px;
    padding-left: 10px;
    margin-left: 5px;
  }
  #DistributionMapDiv {
    z-index: 1;
    height: 400px;
  }
  
  .BlockAMCHARTlogo{
    height: 24px;
    width: 100%;
    background-color: white;
    z-index: 2;
    position: relative;
    top: -20px;
    border-bottom-right-radius: 7px ;
  }

  .DistributionMap-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .DistributionMap-logo {
      animation: DistributionMap-logo-spin infinite 20s linear;
    }
  }
  
  .DistributionMap-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .DistributionMap-link {
    color: #61dafb;
  }
  
  @keyframes DistributionMap-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }