
#TimelineChart {
  width: 100%;
  height: 315px !important;
}

.content{
  text-align: center;
  height: 315px !important;
  margin-top: 1%;
  margin-bottom: 4px;
  margin-left: -26px;
}
