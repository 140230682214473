  .agentContainer {
    width: 100%;
    height: 350px !important;
  }

  .BarsBox2{
    text-align: center;
    height: 345px !important;
    margin-top: 1%;
    margin-bottom: 4px;
    padding-left: 10px;
    margin-left: 5px;
  }

